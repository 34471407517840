<template>
  <Layout tituloPagina="Sistema | Ajustes | Pantillas de facturación">
    <div class="row">
      <div class="col-lg-4">
        <CmpTabs/>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          Ajuste de plantilla de factura
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-8 col-12 mb-3">
            <h5>Variables disponibles</h5>
            <strong>Datos de la empresa: </strong> 
            $nombre_empresa$
            <br>
            <strong>Datos del cliente: </strong>
            <template v-for="(campo, index) in campos">
              <span :key="index" v-if="true">
                {{campo.codigo}} 
                <sup v-show="campo.alias != '' && campo.alias != null">
                  {{campo.alias}}
                </sup>
              </span>
              &nbsp;
            </template>

            <br>
            <strong>Datos de la factura: </strong>
            $numero_serie$, $fecha_expedicion$, $fecha_para_pago$, $fecha_vencimiento$,
            $id_contrato$, $inicio_periodo$, $fin_periodo$, $nota_factura$,
            $partidas_factura$,
            $cargos_globales$, $descuentos_globales$, 
            $sub_total$, $total_cargos_globales$, $total_descuentos_globales$,
            $porcentaje_impuestos$, $impuestos$, $total_factura$, $desglose_abonos$
          </div>
          <div class="col-lg-4 col-12">
            <h5>Tamaño de la impresión</h5>
            Escoge el tamaño que deseas para tus tickets de pago, la media es de <strong>{{ajustes.tamano_factura}}% escala</strong>
            <br><br>
            <Slider v-model="ajustes.tamano_factura" />
          </div>
        </div>

        <div class="mb-3 text-right">
          <button class="btn btn-secondary btn-sm"
            @click="generarPlantilla()">
            Generar plantilla
          </button>
        </div>
        <textarea id="plantillaFactura" style="display: none;"></textarea>
        <br>
        <br>
        <br>

        <!-- Configuración de la tabla $partidas_factura$ -->
        <div>
          <div class="row">
            <div class="col-md-8">
              <h5>Configuración de la tabla $partidas_factura$</h5>
            </div>
            <div class="col-md-4 text-right">
              <button class="btn btn-secondary btn-sm" @click="restablecerColumnasPartidasFactura()">
                <i class="mdi mdi-refresh"></i>
                Restablecer
              </button>
            </div>
          </div>
          
          <hr>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <TblColumnasPartidasFactura :columnas-partidas-factura.sync="ajustes.columnas_partidas_factura"/>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-7">
                  <h5>Configuración de los cargos de las partidas</h5>
                </div>
                <div class="col-md-5 text-right">
                  <button class="btn btn-secondary btn-sm" @click="restablecerColumnasCargosPartidasFactura()">
                    <i class="mdi mdi-refresh"></i>
                    Restablecer
                  </button>
                </div>
              </div>
              
              <div class="table-responsive">
                <TblColumnasCargosPartidasFactura :columnasCargosPartidasFactura.sync="ajustes.columnas_cargos_partidas_factura"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-7">
                  <h5>Configuración de los descuentos de las partidas</h5>
                </div>
                <div class="col-md-5 text-right">
                  <button class="btn btn-secondary btn-sm" @click="restablecerColumnasDescuentosPartidasFactura()">
                    <i class="mdi mdi-refresh"></i>
                    Restablecer
                  </button>
                </div>
              </div>
              
              <div class="table-responsive">
                <TblColumnasDescuentosPartidasFactura :columnasDescuentosPartidasFactura.sync="ajustes.columnas_descuentos_partidas_factura"/>
              </div>
            </div>
          </div>
        </div>
        <!-- Fin de la configuración de la tabla $partidas_factura$ -->

        <br><br><br>
        


        <!-- Configuración de las tablas de cargos y descuentos globales -->
        <div>
          <h5>Configuración de los cargos y descuentos globales de la factura</h5>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-7">
                  <h5>Configuración de $cargos_globales$</h5>
                </div>
                <div class="col-md-5 text-right">
                  <button class="btn btn-secondary btn-sm" @click="restablecerColumnasCargosGlobalesFactura()">
                    <i class="mdi mdi-refresh"></i>
                    Restablecer
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <TblColumnasCargosGlobalesFactura :columnasCargosGlobalesFactura.sync="ajustes.columnas_cargos_globales_factura"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-7">
                  <h5>Configuración de $descuentos_globales$</h5>
                </div>
                <div class="col-md-5 text-right">
                  <button class="btn btn-secondary btn-sm" @click="restablecerColumnasDescuentosGlobalesFactura()">
                    <i class="mdi mdi-refresh"></i>
                    Restablecer
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <TblColumnasDescuentosGlobalesFactura :columnasDescuentosGlobalesFactura.sync="ajustes.columnas_descuentos_globales_factura"/>
              </div>
            </div>
          </div>
        </div>
        <!-- Fin de la configuración de las tablas de cargos y descuentos globales -->

        <br><br><br>

        <!-- Configuración de las tablas de desglose de abonos -->
        <div>
          <h5>Configuración de la tabla de desglose de abonos</h5>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-7">
                  <h5>Configuración de $desglose_abonos$</h5>
                </div>
                <div class="col-md-5 text-right">
                  <button class="btn btn-secondary btn-sm" @click="restablecerColumnasDesgloseAbonosFactura()">
                    <i class="mdi mdi-refresh"></i>
                    Restablecer
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <TblColumnasDesgloseAbonosFactura :columnasDesgloseAbonosFactura.sync="ajustes.columnas_desglose_abonos_factura"/>
              </div>
            </div>
          </div>
        </div>
        <!-- Fin de la configuración de las tablas de desglose de abonos -->
      </div>
    </div>

    <div class="text-right pb-4">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"
import SistemaSrv from '@/services/SistemaSrv.js'
import PlantillaSrv from '@/services/PlantillaSrv.js'
import VistasCampos from '@/services/VistasCampos.js'
import CmpTabs from '../CmpTabs.vue'
import TblColumnasPartidasFactura from './TblColumnasPartidasFactura.vue'
import TblColumnasCargosPartidasFactura from './TblColumnasCargosPartidasFactura.vue'
import TblColumnasDescuentosPartidasFactura from './TblColumnasDescuentosPartidasFactura.vue'
import TblColumnasCargosGlobalesFactura from './TblColumnasCargosGlobalesFactura.vue'
import TblColumnasDescuentosGlobalesFactura from './TblColumnasDescuentosGlobalesFactura.vue'
import TblColumnasDesgloseAbonosFactura from './TblColumnasDesgloseAbonosFactura.vue'

const $ = require("jquery")
import "summernote/dist/summernote-lite.min.css"
import "summernote/dist/summernote-lite.min"
import Slider from "@vueform/slider";

import axios from 'axios'
export default {
  name: 'PlantillaFactura',
  components: {
    Layout,
    CmpTabs,
    Slider,
    TblColumnasPartidasFactura, TblColumnasCargosPartidasFactura, TblColumnasDescuentosPartidasFactura,
    TblColumnasCargosGlobalesFactura, TblColumnasDescuentosGlobalesFactura, TblColumnasDesgloseAbonosFactura
  },
  data() {
    return {
      ajustes: {
        tamano_factura: 4,
        columnas_partidas_factura: [
          // { nombre: 'indice', alias: 'No', visible: true },
          // { nombre: 'producto', alias: 'Producto', visible: true },
          // { nombre: 'cantidad', alias: 'Cantidad', visible: true },
          // { nombre: 'precio_unitario', alias: 'P/U', visible: true },
          // { nombre: 'sub_total', alias: 'S.Total', visible: true },
          // { nombre: 'total_cargos', alias: '+Cargo', visible: true },
          // { nombre: 'total_descuentos', alias: '-Descuento', visible: true },
          // { nombre: 'total', alias: 'Total', visible: true }
        ],
        columnas_cargos_partidas_factura: [
          // { nombre: 'indice', alias: 'No', visible: true },
          // { nombre: 'concepto', alias: 'Concepto', visible: true },
          // { nombre: 'porcentaje', alias: '%', visible: true },
          // { nombre: 'valor', alias: '$', visible: true }
        ],
        columnas_descuentos_partidas_factura: [
          // { nombre: 'indice', alias: 'No', visible: true },
          // { nombre: 'concepto', alias: 'Concepto', visible: true },
          // { nombre: 'porcentaje', alias: '%', visible: true },
          // { nombre: 'valor', alias: '$', visible: true }
        ],

        columnas_cargos_globales_factura: [
          // { nombre: 'indice', alias: 'No', visible: true },
          // { nombre: 'concepto', alias: 'Concepto', visible: true },
          // { nombre: 'porcentaje', alias: '%', visible: true },
          // { nombre: 'valor', alias: '$', visible: true }
        ],
        columnas_descuentos_globales_factura: [
          // { nombre: 'indice', alias: 'No', visible: true },
          // { nombre: 'concepto', alias: 'Concepto', visible: true },
          // { nombre: 'porcentaje', alias: '%', visible: true },
          // { nombre: 'valor', alias: '$', visible: true }
        ],
        columnas_desglose_abonos_factura: [
          // { nombre: 'id', alias: 'Id.', visible: true },
          // { nombre: 'fecha', alias: 'Fecha', visible: true },
          // { nombre: 'hora', alias: 'Hora', visible: true },
          // { nombre: 'abono', alias: 'Abono', visible: true }
        ]
      },
      plantillas: {
        factura: {
          nombre: '',
          contenido: ''
        }
      },
      campos: [
        {campo: 'Número de cliente', alias: '', codigo: '$numero_cliente$'},
        {campo: 'ID', alias: '', codigo: '$id_cliente$'},
        {campo: 'Nombre', alias: '', codigo: '$nombre$'},
        {campo: 'Apellido paterno', alias: '', codigo: '$apellido_paterno$'},
        {campo: 'Apellido materno', alias: '', codigo: '$apellido_materno$'},
        {campo: 'RFC', alias: '', codigo: '$rfc$'},
        {campo: 'Sexo', alias: '', codigo: '$sexo$'},
        {campo: 'Tipo de cliente', alias: '', codigo: '$tipo_cliente$'},
        {campo: 'Estado', alias: '', codigo: '$estado$'},
        {campo: 'Calle', alias: '', codigo: '$calle$'},
        {campo: 'N° ext', alias: '', codigo: '$numero_exterior$'},
        {campo: 'N° int', alias: '', codigo: '$numero_interior$'},
        {campo: 'Colonia', alias: '', codigo: '$colonia$'},
        {campo: 'Delegación o Municipio', alias: '', codigo: '$delegacion$'},
        {campo: 'Código postal', alias: '', codigo: '$codigo_postal$'},
        {campo: 'Estado de residencia', alias: '', codigo: '$estado_residencia$'},
        {campo: 'País', alias: '', codigo: '$pais$'},
        {campo: 'Correo electrónico', alias: '', codigo: '$correo_electronico$'}
      ],
      bandera_spinner: false,
      movil: false
    }
  },

  created: function(){
    var self = this
    
    if (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      window.innerWidth <= 780
    ) {
      self.movil = true
    }

    self.refrescarAjustes()
  },
  mounted() {
    let self = this

    $(document).ready(function() {
      var toolbar = [
        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['height', ['height']],
        ['view', ['codeview', 'help']]
      ]

      if (self.movil) {
        toolbar = [
          ['style', ['style', 'bold', 'italic', 'underline']],
          ['fontsize', ['fontsize', 'color']],
          ['para', ['ul', 'paragraph']],
          ['table', ['table', 'height']],
          ['view', ['codeview']]
        ]
      }

      $('#plantillaFactura').summernote({
        height: 600,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.factura.contenido = contents
          }
        },
        toolbar: toolbar
      })
      $('.note-editable').css('background-color', '#FFFFFF')
    })

    setTimeout(function(){
      self.refrescarPlantilla()
      self.refrescarCampos()
    }, 450)
  },

  methods: {
    actualizar() {
      let self = this,
        ajustes = Object.assign({}, self.ajustes)

      self.bandera_spinner = true

      // Actualización de los ajustes
      let prom1 = SistemaSrv.actualizar(ajustes)

      // Actualización de la plantilla de la factura
      let prom2 = PlantillaSrv.actualizar('factura', self.plantillas.factura.contenido)

      axios.all([prom1, prom2]).then(response => {
        iu.msg.success('Se actualizaron los datos')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    numero: function(n){
      return parseFloat(n).toFixed(2)
    },

    generarPlantilla: function () {
      let self = this
      PlantillaSrv.plantillaPorDefectoJSON('factura').then(response => {
        let factura = response.data
        
        Object.assign(self.plantillas.factura, factura)
        $('#plantillaFactura').summernote('code',factura.contenido)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la plantilla de factura por defecto'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },


    refrescarCampos () {
      let self = this

      VistasCampos.vistasCamposJSON().then(response => {
        let campos = response.data
        campos = campos.filter(campo => {
          return campo.id_vista == 36
        })

        campos.forEach( campo1 => {
          let index = self.campos.findIndex(campo2 => campo1.campo == campo2.campo)
          if(index == -1) return
          self.campos[index].alias = campo1.alias
        })
      })
    },

    refrescarAjustes() {
      let self = this
      let nombresAjustes = Object.keys(self.ajustes)

      SistemaSrv.ajustes(nombresAjustes).then(response => {
        let ajustes = response.data
        self.ajustes.tamano_factura = ajustes.tamano_factura

        nombresAjustes.forEach(na => {
          try {
            self.ajustes[na] = JSON.parse(ajustes[na])
          } catch (e) {
            console.log(`${na} no es JSON`)
          }
        })
      })
    },

    refrescarPlantilla: function() {
      let self = this
      
      PlantillaSrv.plantillaJSON('factura').then(response => {
        let ticketPago = response.data
        Object.assign(self.plantillas.factura, ticketPago)
        $('#plantillaFactura').summernote('code',ticketPago.contenido)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la plantilla de ticket de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    restablecerColumnasCargosGlobalesFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_cargos_globales_factura').then(response => {
        self.ajustes.columnas_cargos_globales_factura = response.data
      })
    },

    restablecerColumnasDesgloseAbonosFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_desglose_abonos_factura').then(response => {
        self.ajustes.columnas_desglose_abonos_factura = response.data
      })
    },


    restablecerColumnasDescuentosGlobalesFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_descuentos_globales_factura').then(response => {
        self.ajustes.columnas_descuentos_globales_factura = response.data
      })
    },

    restablecerColumnasCargosPartidasFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_cargos_partidas_factura').then(response => {
        self.ajustes.columnas_cargos_partidas_factura = response.data
      })
    },

    restablecerColumnasDescuentosPartidasFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_descuentos_partidas_factura').then(response => {
        self.ajustes.columnas_descuentos_partidas_factura = response.data
      })
    },

    restablecerColumnasPartidasFactura() {
      let self = this

      SistemaSrv.valorPorDefectoAjuste('columnas_partidas_factura').then(response => {
        self.ajustes.columnas_partidas_factura = response.data
      })
    }
  }
}
</script>

<style>
</style>