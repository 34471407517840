<template>
  <table style="width: 100%">
    <tr>
      <td>
        <input type="text" value="Columna" readonly class="form-control">
      </td>
      <td v-for="(columna, index) in columnasDescuentosGlobalesFactura" :key="`tblpf1-${index}`">
        <input type="text" v-model="columna.nombre" readonly class="form-control">
      </td>
    </tr>
    <tr>
      <td>
        <input type="text" value="Alias" readonly class="form-control">
      </td>
      <td v-for="(columna,index) in columnasDescuentosGlobalesFactura" :key="`tblpf1-${index}`">
        <input type="text" v-model="columna.alias" class="form-control">
      </td>
    </tr>
    <tr>
      <td>
        <input type="text" value="Visible" readonly class="form-control">
      </td>
      <td v-for="(columna,index) in columnasDescuentosGlobalesFactura" :key="`tblpf1-${index}`">
        <div class="text-center">
          <input type="checkbox" v-model="columna.visible">
        </div>
      </td>
    </tr>
    <tr>
      <td>
        <input type="text" value="Posición" readonly class="form-control">
      </td>
      <td v-for="(columna,index) in columnasDescuentosGlobalesFactura" :key="`tblpf1-${index}`">
        <div class="text-center">
          <div class="btn-group">
            <button class="btn btn-soft-dark" v-show="index > 0"
              @click="moverColumnaALaIzquierda(index)">
              <i class="fa fa-angle-left"></i>
            </button>
            <button class="btn btn-soft-dark" v-show="index < columnasDescuentosGlobalesFactura.length - 1"
              @click="moverColumnaALaDerecha(index)">
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'TblColumnasDescuentosGlobalesFactura',
  props: [ 'columnasDescuentosGlobalesFactura' ],
  methods: {
    moverColumnaALaIzquierda(index) {
      var self = this
      // Si la columna a mover está en la primera posición se evita continuar
      if(index == 0) return
      
      let columna = Object.assign({}, self.columnasDescuentosGlobalesFactura[index])
      self.columnasDescuentosGlobalesFactura.splice(index,1)
      self.columnasDescuentosGlobalesFactura.splice(index-1,0,columna)
    },

    moverColumnaALaDerecha(index) {
      var self = this
      let ultimaPosicion = self.columnasDescuentosGlobalesFactura.length - 1
      // Si la columna a mover está en la última posición se evita continuar
      if(index == ultimaPosicion) return

      let columna = Object.assign({}, self.columnasDescuentosGlobalesFactura[index])
      self.columnasDescuentosGlobalesFactura.splice(index,1)
      self.columnasDescuentosGlobalesFactura.splice(index+1,0,columna)
    },
  }
}
</script>